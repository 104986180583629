.news h2 {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: -0.02em;
  padding: 30px 0px 14px;
}
.news h2:first-of-type {
  padding-top: 0;
}

.news-title {
  position: relative;
}
