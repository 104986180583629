.newsRow {
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--border-color);
  padding: 22px 0;
}

.newsRow-imageWrapper {
  text-align: center;
}

.newsRow-image {
  background: #FFFFFF;
  transition: transform .7s;
  border: 1px solid var(--border-color-light);
  border-radius: 5px;
  overflow: hidden;
  height: max-content;
  
  img {
    transition: transform .7s;
    height: 100px;
    width: 200px;
    object-fit: cover;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.newsRow:last-of-type {
  border-bottom: 1px solid var(--border-color);
}

.newsRow-tag {
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: var(--xs-font-size);
  font-weight: bold;
  margin-bottom: 0;
  padding: 2px 10px;
  margin-right: 3px;
  color: #FFFFFF;
}

.newsRow-date {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  white-space: nowrap;
}

.newsRow-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

.newsRow-headerWrapper {
  align-items: center;
  display: flex;
  flex-grow: 0;
  gap: 5px;
}

.newsRow-preview {
  color: black;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  margin-bottom: 0px;
  text-decoration: none;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.newsRow-preview:hover {
  cursor: pointer;
  text-decoration: underline;
}
.no-content:hover {
  cursor: auto !important;
  text-decoration: none !important;
}
.newsRow-preview a:visited, .newsRow-preview a:active {
  color: black;
}

.news-paginator {
  align-items: center;
  display: flex;
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  gap: 15px;
  justify-content: center;
  line-height: 0px;
  padding: 40px;
}
.news-paginator img, .news-paginator span {
  cursor: pointer;
}

.news-no-select {
  border-radius: 16px;
  display: flex;
  height: 16px;
  justify-content: center;
  padding: 15px;
  width: 16px;
  border: 1px solid #fff;
  transition: all .2s ease-out;
  
  &:hover {
    background: #989898;
    border: 1px solid #989898;
    color: white;
  }
}

.news-selectedPage {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 16px;
  color: white;
  display: flex;
  height: 16px;
  justify-content: center;
  padding: 15px;
  width: 16px;
}

.newsRow-content a {
  text-decoration: none;
}

@media only screen and (max-width: 576px) {
  .newsRow-preview {
    -webkit-line-clamp: 3;
  }

  .newsRow img {
    width: 110px;
    height: 58px;
  }

  .news-paginator {
    gap: 6px;
  }
}
