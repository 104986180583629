.loading {
  animation: finish-loading 3s 1;
  -webkit-animation: finish-loading 3s 1;
  animation-iteration-count: 1;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  scrollbar-width: none;
  width: 100%;
  -ms-overflow-style: none;
  z-index: -1;
}
.loader::-webkit-scrollbar {
  display: none;
}

.loader {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.ball-scale-multiple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; 
}
.ball-scale-multiple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; 
}
  
.ball-scale-multiple > div {
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: absolute;
  left: -60px;
  opacity: 0;
  width: 120px;
  height: 120px;
  -webkit-animation: ball-scale-multiple 2s 0s 2;
  animation: ball-scale-multiple 2s 0s 2; 
}

@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; 
  }
  5% {
    opacity: 1; 
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; 
  } 
}

@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; 
  }
  5% {
    opacity: 1; 
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; 
  } 
}

@keyframes finish-loading {
  0% {
    background-color: var(--primary-color);
    opacity: 1;
    transition: opacity 10s ease-out;
    visibility: visible;
    z-index: 101;
  }
  70% {
    background-color: var(--primary-color);
    opacity: 1;
    z-index: 101;
  }
  100% {
    background-color: transparent;
    display: none;
    opacity: 0;
    transition: opacity 10s ease-out;
    visibility: hidden;
    z-index: 0;
  }
}

@-webkit-keyframes finish-loading {
  0% {
    background-color: var(--primary-color);
    opacity: 1;
    transition: opacity 10s ease-out;
    visibility: visible;
    z-index: 101;
  }
  70% {
    background-color: var(--primary-color);
    opacity: 1;
    z-index: 101;
  }
  100% {
    background-color: transparent;
    display: none;
    opacity: 0;
    transition: opacity 10s ease-out;
    visibility: hidden;
    z-index: 0;
  }
}
