p {
  line-height: 180%;
  letter-spacing: 0.32px;
}

.home {
  --home-page-last-section-margin-bottom:100px;

  .header, .hamburgerMenu {
    color: white;
    position: fixed;
    width: 100%;
    z-index: 1;
  }
}

.home-video {
  --scroll-border-width:74px;
  position: relative;
  background: #000;
  height: 100vh;
  margin-bottom: 70px;
  video {
    height: 100vh;
    object-fit: cover;
  }
}

.home-title {
  text-align: center;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  top: 50%;

  h1 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 42px;
    line-height: 42px
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }
}

.home-scroll {
  writing-mode: tb-rl;

  &:hover {
    cursor: pointer;
  }
}

.home-title{
  width: 100%;
}
.home-title, .home-scroll, .home-scrollBorder {
  color: white;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.home-scroll {
  padding-bottom: 36px;
}

.home-scrollText {
  border-right: 1px solid white;
  display: inline-block;
  font-family: var(--font-family);
  line-height: 24px;
  margin-bottom: 36px;
  padding-bottom: var(--scroll-border-width);
  padding-right: 3px;
}

.home-news, .home-careers, .home-company {
  margin: auto;
  padding: 0 !important;
}

.home-companyContent {
  margin: auto;
}

.home-serviceContent {
  width: 90%;
  margin: auto;
}

.home-serviceContent .service-image {
  transition-duration: 0.5s;

  &:hover {
    transform: scale(1.05);
  }
}

.home-companyContent {
  display: grid;
  grid-template-columns: 44.5fr 36px 45fr;
}
.home-serviceContent {
  display: grid;
  gap: 8px;

  .home-buildWithUsImage {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;

    a {
      padding: 20px;
    }
  }
}
.home-careersContent {
  display: grid;
  grid-template-columns: 34.5fr 36px 45fr;
}

.home-careersIntro .navLink, .home-companyIntro .navLink {
  float: right;
}

.home-careersIntro .navLink p, .home-companyIntro .navLink p {
  margin-top: var(--spacing-5);
}

.home-companyTitles, .home-careersIntro {
  line-height: 48px;
  margin-bottom: var(--spacing-size-d);
  h1{
    font-size: 38px;
    margin-bottom: 30px;
    font-weight: 700;
  }
  h3 {
    font-weight: 600;
    line-height: var(--spacing-4);
  }
}

.home-companySubtitle {
  font-size: var(--ml-font-size);
  font-weight: bold;
}

.home-careersIntro{
  h1{
    font-size: 38px;
    margin-bottom: 30px;
    font-weight: 700;
  }
  h3{
    font-size: var(--l-font-size);
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
  }
}

.home-companyIntro h3 {
  font-size: var(--l-font-size);
  font-weight: 600;
  margin: 0;
}

.home-companyIntroContent p, .home-careersIntro p {
  font-size: 16px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.home-companyImage img {
  border-radius: 10px;
  box-shadow: var(--image-box-shadow);
  width: 100%;
}
.home-buildWithUsImage img{
  border-radius: 20px;
  box-shadow: var(--image-box-shadow);
  width: 100%;
  transform: scale(1);
  transition: .5s all;
  // &:hover {
  //   transform: scale(1.05);
  // }
}
.home-news{
  .h2-en{
    padding: 0px 0 40px 0;
    margin-bottom: 0px;
    line-height: 1;
  }
}
.home-newsPreview {
  display: grid;
  gap: var(--spacing-3);
  justify-items: center;
  .newsCard{
    max-width: initial;
    width: 100%;
    padding-top: 0px;
  }
  .newsRow-preview{
    font-size: 14px;
    line-height: 160%;
    font-weight: 500;
  }
  .newsRow-tag{
    // margin-left: auto;
    height: 21px;
  }
}

.home-navButtonWrapper {
  margin: 40px auto 70px auto;
  text-align: center;

  .nav-buttonText {
    height: 70px;
    width: 285px;
    font-weight: bold;
    font-size: 20px;
  }
}

.home-careers {
  margin-bottom: 90px;
  .h2-en{
    padding-top: 90px;
    margin-bottom: 40px;
  }
}

.home-company{
  h2 {
    margin-bottom: 40px;
    margin-top: 0px;
  }
  .home-navButtonWrapper{
    margin-bottom: 100px;
  }
}

.home-careers h2 {
  margin-bottom: var(--spacing-2);
  margin-top: 0px;
}
// Service
.home-service {
  background-color: #000;
  padding-bottom: 90px;
  padding-top: 90px;
  .container >.h2-en-white{
    padding: 0px 0 20px 0;
    margin-bottom: 0px;
  }
  .service-item-title {
    padding: 0px;
    font-size: 40px;
    margin-bottom: 30px;
  }
  p {
    font-size: var(--ml-font-size);
    line-height: 180%;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 991px) {
  .home-serviceContent {
    gap: 40px;
  }
}

@media only screen and (max-width: 968px) {
  .home-newsPreview {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 768px) {
  .home {
    --home-page-video-height:350px;
  }

  .home-newsPreview {
    gap: 0;
    grid-template-columns: none !important;
    padding: 0;
  } 

  .home-newsPreview {
    .newsCard {
      font-size: 12px;
      padding-top: 16px;
    }
    .newsRow-preview{
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .home {
    --page-side-padding:var(--spacing-1);
    --navigation-button-width:285px;
  }

  .home-news h2 {
    padding: 30px, 0px, 14px, 0px;
  }

  .home-companyIntro h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .home-careersIntro h3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .home-companySubtitle {
    line-height: var(--spacing-3);
    margin-top: var(--spacing-size-e);
  }

  .home-navButtonWrapper {
    margin: auto;
    margin: 30px 32.5px 50px 32.5px;

    button {
      padding: 0;
      width: var(--navigation-button-width);
    }
  }

  div.nav-buttonText {
    // padding: 16.5px 57.65px 18.5px 57.65px;
    width: var(--navigation-button-width);
  }

  .home-companyContent, .home-careersContent {
    flex-direction: column;
    gap: var(--spacing-1);
    grid-template-columns: 1fr !important;
  }

  .home-companyImage, .home-newsPreview {
    margin-left: 0;
  }

  .home-companyIntroContent {
    margin-bottom: var(--spacing-1);
  }

  .home-careers {
    margin-bottom: var(--spacing-2);
  }

  .home-company h2, .home-careers h2 {
    padding: 0 0 var(--spacing-size-d) 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .newsCard {
    height: 125px;
  }

  .container {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .home-title {
    width: 100%;

    h1 {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 3px;
    }

    p {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
    }
  }

  .home-services {
    padding: 0 !important;
    max-width: max-content;
  }

  .home-video {
    height: 400px;
    margin-bottom: 20px;
    video {
      height: 400px;
    }
  }

  .home-news .h2-en{
    padding-bottom: 30px;
  }

  .home-companyContent, .home-serviceContent {
    width: 100%;
  }

  .home-company .home-navButtonWrapper{
    margin-bottom: 50px;
  }

  .home-companyTitles, .home-careersIntro {
    h1 {
      font-size: 22px;
      margin-bottom: 20px;
      line-height: 150%;
    }
  }

  .home-serviceContent {
    .home-buildWithUsImage {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 20px;
      justify-content: center;
      align-items: center;
  
      a {
        padding: 20px 0;
      }
    }
  }

  .home-service {
    padding: 40px 0;
    .service-item-title {
      font-size: 26px;
    }
  }

  .home-careers .h2-en{
    padding-top: 40px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 391px) {
  .services {
    --services-slide-bottom-spacing:20px;
  }
}
