.title {
  --page-title-font-size: 42px;
  --subtitle-font-size: 17px;

  background-image: url('../../images/title-bg.png');
  background-color: var(--primary-color);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  white-space: nowrap;
}
.title h1 {
  font-family: 'Oswald';
  font-weight: 600;
  font-size: var(--page-title-font-size);
}
.title p {
  font-size: var(--subtitle-font-size);
  margin-bottom: 50px;
  margin-top: 0;
  display: flex;
  justify-content: center;
}

.title .header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
}
.title .header a {
  color: white;
}

.title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 70px;
  
  h1 {
    font-size: 54px;
  }
  p {
    font-size: 16px;
  }
}

.title-blackBorder {
  background-color: black;
  height: 10px;
  width: 100%;
}

.title-content h1 {
  margin-bottom: 14px;
  margin-top: 50px;
}

@media only screen and (max-width: 576px) {
  .title-content h1 {
    font-size: 40px;
  }
  
  .title-content p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 390px) {
  .title h1 {
    --page-title-font-size: 30px;
    --subtitle-font-size: 12px;
  }

  .title-content h1 {
    margin-top: 65px;
  }

  .title p {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
  }
}
