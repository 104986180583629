.content {
  color: black;
  padding: 40px 110px;
}

.content h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}

.content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
}

.content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  padding: 30px 0 14px;
}

.content h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 22px 0 10px;
}

.content h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 180%;
}

.content h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 180%;
}

.content .lg-para {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
}

.content .mid-para {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}

.content .content-date {
  margin-top: 40px;
}

.content a {
  color: var(--primary-color);
  text-decoration: underline;
}
.content a:visited, .content a:active {
  color: var(--primary-color);
}
.content a[href^="mailto"] { 
  text-decoration: none;
}

.content ul, .content li, .content ol {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.content li {
  list-style-position: inside;
  padding: 6px 0;
}

.content ol {
  list-style-position: inside;
  padding: 3px 0;
}

.content table {
  border-collapse: collapse;
  margin: 22px 0 10px;
}

.content th {
  background-color: #efefef;
  border: 1px solid var(--border-color);
  border-collapse: collapse;
  padding: 5px 10px;
}

.content td {
  padding: 5px 10px;
}

.socialList ul a{
  margin-left: 5px;
  color: #333;
}
.socialList ul a:hover{
  color: #888;
}

@media only screen and (max-width: 576px) {
  .content {
    padding: 40px 20px;
  }
}
