.pageNotFound-content {
  --question-mark-height: 83.33px;
  --content-font-size: 22px;

  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: var(--content-font-size);
  padding: 50px 0;
}

.pageNotFound .nav-button {
  margin: 40px 0;
}

.pageNotFound-questionMark {
  height: var(--question-mark-height);
}

@media only screen and (max-width: 900px) {
  .pageNotFound-content {
    padding: 40px 0;
    text-align: center;
  }

  .pageNotFound .footer {
    height: 326px;
    position: sticky;
  }
}
