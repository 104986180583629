.whiteTitle {
  --page-title-font-size: 40px;
  --white-title-height: 283px;
  --white-title-padding-top: 128px;

  color: black;
}

.whiteTitle h1 {
  font-weight: 600;
  line-height: 60px;
}

.whiteTitle-content img {
  padding: var(--white-title-padding-top) 0 18px 0;
}

.whiteTitle-content {
  /* height: var(--white-title-height); */
  text-align: center;
}

.whiteTitle-subtitle {
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@media only screen and (max-width: 576px) {
  .whiteTitle {
    --white-title-height: 250px;
    --white-title-padding-top: 68px;
    padding-top: 30px;
  }

  .whiteTitle h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 10px;
  }

  .whiteTitle-subtitle {
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
    white-space: nowrap;
  }
}
