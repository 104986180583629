#headerWrapper {
  position: fixed;
  width: 100%;
  z-index: 100;
}

#main-content-mobile-logo {
  display: none;
  position: absolute;
  top: 38px;
  left: 30px;
  z-index: 1;

  & img {
    height: 20px;
  }
}

@media only screen and (max-width: 776px) {
  #headerWrapper .header {
    display: none;
  }

  #headerWrapper {
    position: unset;

    #main-content-mobile-logo {
      display: block;
    }
  }
}