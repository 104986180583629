#backToTop {
  --back-to-top-width: 48px;

  bottom: 60px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #777777;
  cursor: pointer;
  height: var(--back-to-top-width);
  padding-top: 4px;
  position: fixed;
  right: 30px;
  transition: var(--hover-transition);
  width: var(--back-to-top-width);
  z-index: var(--layer-lift);
}

#backToTop:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
}
#backToTop:hover .arrow {
  border-left: 1px solid black;
  border-top: 1px solid black;
}


/* 進入過渡效果 */
.scroll-to-top-enter {
  opacity: 0;
  transform: translateY(50%);
}

.scroll-to-top-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* 退出過渡效果 */
.scroll-to-top-exit {
  opacity: 1;
  transform: translateY(0);
}

.scroll-to-top-exit-active {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}