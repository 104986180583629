.nav-button {
  background-color: #000;
  /* background: linear-gradient(to right, white 50%, black 50%); */
  /* background-position: right bottom; */
  border: 1px solid #000;
  border-radius: var(--spacing-size-b);
  transition: all .3s ease-out;
}
.nav-button:hover {
  background-color: #fff;
  transition: all .3s ease-out;
}
.nav-button:hover div {
  color: #000;
}
.nav-button:hover .arrow {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}

.nav-buttonText {
  align-items: center;
  color: white;
  display: flex;
  font-size: 17px;
  margin: 0;
  justify-content: center;
  text-align: center;
  transition: all .6s ease-out;
}
.nav-buttonText:hover {
  text-decoration: none;
}
.nav-buttonText img {
  margin-left: 10px;
}
.nav-buttonText > .arrow {
  margin-left: 10px;
  margin-right: 0;
}

/* --- */

.nav-white-button {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: var(--spacing-size-b);
  transition: all .3s ease-out;
  margin-right: 10px;

  &:hover {
    background-color: #000;
    transition: all .3s ease-out;
  }
  &:hover div {
    color: #fff;
  }
  &:hover .arrow {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
  }

  .arrow {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
  }


  .nav-buttonText {
    align-items: center;
    color: #000;
    display: flex;
    font-size: 17px;
    margin: 0;
    justify-content: center;
    text-align: center;
    transition: all .6s ease-out;
  }
  .nav-buttonText:hover {
    text-decoration: none;
  }
  .nav-buttonText img {
    margin-left: 10px;
  }
  .nav-buttonText > .arrow {
    margin-left: 10px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .nav-white-button {
    margin-bottom: 10px;
    margin-right: 0;
  }
}