.footer {
  --footer-padding-top: 20px;
  --footer-top-section-link-padding: 43px;
  --footer-top-link-height: 16px;
  --footer-middle-section-link-padding: 13px;
  --footer-middle-link-border-color: #989898;
  --trademark-border-color: #818181;
  --trademark-font-size: 10px;
  --trademark-padding-bottom: 12px;

  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: var(--xs-font-size);
  height: var(--footer-height);
  justify-content: space-between;
  padding-top: var(--footer-padding-top);
  width: 100%;
}

.footer-topSection,
.footer-middleSection {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.footer-topSection a {
  color: white;
  padding: 0 var(--footer-top-section-link-padding);
}

.footer-middleSection a {
  padding: 0 var(--footer-middle-section-link-padding);
}

.footer-middleLinks a,
.footer-middleLinks a:visited,
.footer-middleLinks a:active {
  color: white;
}

.footer-topLinks {
  line-height: 16px;
  text-align: center;
  line-height: 150%;
}

.footer-topLinks a {
  margin-bottom: 5px;
}

.footer-topLinks a {
  border-right: 1px solid var(--footer-middle-link-border-color);
}

.footer-topLinks a:nth-of-type(1) {
  border-left: 1px solid var(--footer-middle-link-border-color);
}

.footer-middleLinks {
  line-height: var(--footer-middle-section-link-padding);
}

.footer-middleLinks a {
  border-right: 1px solid var(--footer-middle-link-border-color);
}

.footer-middleLinks a:nth-of-type(1) {
  border-left: 1px solid var(--footer-middle-link-border-color);
}

.footer-contactButton {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  color: black;
  display: flex;
  font-size: var(--sm-font-size);
  padding: 12px 30px;
  transition: var(--hover-transition);
  border: 1px solid white;
}

.footer-contactButton .arrow {
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.footer-contactButton div {
  margin: 0;
  height: 11px;
  width: 11px;
}

.footer-contactButton:hover {
  background-color: black;
  border: 1px solid white;
  cursor: pointer;
  color: white;
}

.footer-contactButton:hover .arrow {
  border-left: 1px solid white;
  border-top: 1px solid white;
}

.footer-trademark {
  border-top: 1px solid var(--trademark-border-color);
  font-family: var(--secondary-font);
  font-size: var(--trademark-font-size);
  text-align: center;
  width: 100%;
}

.footer-trademark p {
  margin: 0;
  padding-bottom: var(--trademark-padding-bottom);
  padding-top: 10px;
}

.footer a {
  display: inline-block;
  text-decoration: none;
}

.footer a:hover {
  color: white;
  text-decoration: underline;
}

.footer a:visited,
.footer a:active {
  color: white;
  text-decoration: none;
}

#footer-mobile {
  display: none;
  flex-direction: column;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: white;
  background-color: black;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  line-height: 150%;

  .footer-mobile-title {
    display: flex;
    flex-direction: column;
    padding: 48px 0;
    font-weight: bold;
    word-spacing: 3.6px;
    border-bottom: #818181 1px solid;
  }

  .footer-mobile-menu-link {
    padding: 30px 0;
    font-weight: bold;
    border-bottom: #818181 1px solid;
    color: white !important;
    text-decoration: none !important;

    .footer-mobile-menu-link-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footer-mobile-submenu-toggle {
        display: flex;
        align-items: center;
        transition: transform .3s;
      }
    }
  }

  .footer-mobile-submenu-area {
    height: 0;
    overflow: hidden;
    transition: height .3s;

    .footer-mobile-submenu-link {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 12px;

      a {
        font-weight: normal;
        color: white !important;
        text-decoration: none !important;
      }
    }
  }

  .footer-mobile-policy {
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    padding-bottom: 50px;

    a {
      padding: 6px 0;
      color: white !important;
      text-decoration: none !important;
    }
  }

  .footer-mobile-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .footer {
    height: 100%;
  }

  .footer-topSection,
  .footer-middleSection {
    flex-direction: column;
    align-items: center;
  }

  .footer-topSection {
    gap: 35px;
    width: 100%;
  }

  .footer-middleSection {
    gap: 25px;
  }

  .footer-middleLinks {
    text-align: center;
    line-height: 150%;
  }

  .footer-middleLinks a {
    margin-bottom: 5px;
  }

  .footer-trademark {
    --trademark-padding-bottom: 60px;
  }
}

@media only screen and (max-width: 900px) {
  .footer-contactButton {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    display: none;
  }

  #footer-mobile {
    display: flex;
  }
}