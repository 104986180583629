:root {
  --font-family: 'Oswald', sans-serif;
  --secondary-font: 'Inter', sans-serif;
  --jp-font-family: 'Noto Sans JP', "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;

  --xl-font-size: 32px;
  --l-font-size: 24px;
  --ml-font-size: 16px;
  --m-font-size: 14px;
  --sm-font-size: 12px;
  --xs-font-size: 11px;

  --border-color: #BFBFBF;
  --border-color-light: #D9D9D9;
  --primary-color: #204377;
  --news-category-green: #207772;

  --image-box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.3);

  --content-gap: 50px;
  --layer-lift: 10;
  --footer-height: 200px;
  --page-side-padding: 110px;
  --page-content-width: 70%;
  --page-video-height: 380px;

  --hover-transition: 0.5s;

  --spacing-size-a: 2px;
  --spacing-size-b: 6px;
  --spacing-size-c: 8px;
  --spacing-size-d: 10px;
  --spacing-size-e: 12px;
  --spacing-size-f: 14px;
  --spacing-size-g: 16px;

  --spacing-1: 20px;
  --spacing-2: 24px;
  --spacing-3: 26px;
  --spacing-4: 28px;
  --spacing-5: 30px;
}

h3,
h4 {
  color: black;
}

p {
  margin: 0;
}

a:visited,
a:active {
  color: white;
}

.section-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--content-gap);
}

/*暫時移除動畫效果*/
.section-animation {
  opacity: 1;
}

.section-animation.active {
  /*animation: fade-bottom-to-top .5s ease-in;*/
  opacity: 1;
}

.short-page-adjustment {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.short-content-adjustment {
  display: flex;
  flex-direction: column;
  min-height: 55vh;
}

.title-with-bg-wrapper,
.subtitle-with-bg-wrapper {
  display: inline-block;
  position: relative;
  margin-bottom: 40px;
}

.subtitle-with-bg-wrapper {
  margin: 8px 0 40px;
}

.title-with-bg-wrapper i,
.subtitle-with-bg-wrapper i {
  background: var(--primary-color);
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0%;
}

.title-animation {
  background: linear-gradient(to left, var(--primary-color) 50%, transparent 50%);
  color: white;
}

.title-reverse-animation {
  background: linear-gradient(to right, transparent 50%, var(--primary-color) 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  display: grid;
  transition: all .5s ease-out;
}

.title-animation.active {
  transition: width .5s;
  width: 100%;
}

.title-reverse-animation.active,
.title-with-bg-wrapper {
  background-position: right top;
  padding: 16px 16px 16px 16px;
}

.title-with-white-bg {
  background-color: white;
  color: var(--primary-color);
  display: inline-block;
  font-family: var(--font-family);
  font-size: var(--xl-font-size);
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: var(--spacing-size-c);
  padding: var(--spacing-size-g);
  white-space: nowrap;
}

.title-with-bg {
  color: white;
  display: inline-block;
  font-size: 46px;
  font-family: var(--font-family);
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.92px;
  position: relative;
  padding-left: 10px;
  padding-right: 0;
  white-space: nowrap;
}

.subtitle-with-white-bg {
  background-color: white;
  color: var(--primary-color);
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
  padding: 7px 10px;
}

.subtitle-with-bg {
  color: white;
  display: inline-block;
  font-family: var(--jp-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
  padding-left: 10px;
  position: relative;
}

.title-right-align {
  float: right;
}

#h2-default,
.h2-default {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  padding: 30px 0px 14px;
}

.h2-en {
  color: black;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 47px;
  letter-spacing: -0.02em;
  padding: 0px;
}

.h2-en-white {
  color: #ffffff;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 47px;
  letter-spacing: -0.02em;
  padding: 40px 0;
}

.h3-en-white {
  color: #ffffff;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: -0.02em;
  padding: 40px 0 14px;
}

.white {
  color: #ffffff;
}

.h3-default,
#h3-default {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 170%;
}

.text-link {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.jp-12 {
  color: #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.jp-12-bold {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.jp-14 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.02em;
}

@media only screen and (max-width: 576px) {
  :root {
    --content-gap: 20px;
  }

  .section-content {
    grid-template-columns: 1fr;
  }

  .title-with-bg {
    font-size: 28px;
  }

  .subtitle-with-bg {
    font-size: 16px;
  }

  .h2-en {
    font-size: 38px;
  }
}

@keyframes fade-bottom-to-top {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}