.areaCodeSelectWrapper {
  position: relative;
}
.areaCodeSelectWrapper .arrow {
  border-top: 1px solid black;
  border-left: 1px solid black;
  bottom: 1px;
  position: absolute;
  right: 25%;
  height: 6px;
  width: 6px;
  z-index: 1;
}

.areaCodeSelect {
  background: #FFFFFF;
  border: 2px solid var(--border-color);
  border-radius: 6px;
  color: black;
  margin-right: 15px;
  padding: 9px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-indent: 1px;
}
