#main-content-fill {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 0;
  background-color: #2f2f2f;
  transition: height .5s cubic-bezier(.89, .05, .27, 1);

  &.show {
    height: 20px;
  }
}

#side-bar-mobile {
  display: none;
  flex-direction: column;
  gap: 50px;
  padding-top: 38px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 0;
  right: 0;
  width: 374px;
  height: 100dvh;
  overflow: hidden;
  font-family: 'Noto Sans', sans-serif;
  background-color: #2f2f2f;
  transform: translateX(100%);
  transition: transform .5s cubic-bezier(.89, .05, .27, 1);

  &.show {
    transform: translateX(0);
  }

  &::before {
    content: "";
    position: fixed;
    top: 108px;
    width: 100%;
    height: 30px;
    background-image: linear-gradient(to top, rgba(47, 47, 47, 0), rgba(48, 48, 48, 1));
    z-index: 20;
  }

  &::after {
    content: "";
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-image: linear-gradient(to bottom, rgba(47, 47, 47, 0), rgba(48, 48, 48, 1));
    z-index: 20;
  }

  .side-bar-mobile-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    overflow-y: scroll;
    overscroll-behavior-y: none;
    transform: translateZ(0);

    .side-bar-mobile-menu-link {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 30px 0;
      border-bottom: #888888 1px solid;
      color: white;
      text-decoration: none;
      cursor: pointer;

      span:first-of-type {
        font-size: 12px;
        font-weight: bolder;
      }

      span:last-of-type {
        font-size: 11px;
      }
    }

    .side-bar-mobile-menu-fakeBustersLink {
      margin-top: 16px;
      margin-left: auto;
    }
  }

  .side-bar-mobile-submenu {
    position: absolute;
    top: 108px;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    overflow-y: scroll;
    overscroll-behavior-y: none;
    background-color: #2f2f2f;
    color: white;
    transform: translateX(100%);
    transition: transform .5s cubic-bezier(.89, .05, .27, 1);

    &.show {
      transform: translateX(0);
    }

    .submenu-header {
      display: flex;
      gap: 40px;
      align-items: center;
      border-bottom: #888888 1px solid;
      cursor: pointer;

      .submenu-back-icon {
        width: 20px;
        height: 40px;
        transform: rotate(180deg);
      }

      .submenu-title {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-top: 30px;
        padding-bottom: 30px;

        span:first-of-type {
          font-size: 12px;
          font-weight: bolder;
        }

        span:last-of-type {
          font-size: 11px;
        }
      }
    }

    .submenu-link:first-of-type {
      span {
        font-weight: bold;
      }
    }

    .submenu-link {
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 22px 0;
      border-bottom: #888888 1px solid;
      color: white;
      text-decoration: none;
      font-size: 12px;

      .submenu-navigation-icon {
        width: 12px;
        height: 12px;
        margin-left: 3px;
      }
    }
  }
}

#side-bar-mobile-toggle {
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 38px;
  padding-right: 20px;
  display: none;
  place-content: flex-end;
  width: fit-content;
  /* loading => 101 */
  z-index: 100;

  .toggle-icon {
    width: 22px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image .5s ease-in;

    &.more-white {
      background-image: url('../../images/more_white.svg');
    }

    &.more-black {
      background-image: url('../../images/more_black.svg');
    }

    &.cancel {
      background-image: url('../../images/cancel_icon.svg') !important;
    }
  }
}

@media only screen and (max-width: 776px) {

  #side-bar-mobile,
  #main-content-fill,
  #side-bar-mobile-toggle {
    display: flex;
  }
}