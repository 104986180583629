html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;

}
html,
body,
#root {
  background-color: white;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

#root {
  background-color: #2f2f2f !important;

  .main-content {
    background-color: white;
    transition: transform .5s cubic-bezier(.89, .05, .27, 1);

    &.go-down {
      transform: translate(-374px, 20px);
    }
  }
}

ol,
ul,
h1,
h2,
h4 {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

.button:hover {
  cursor: pointer;
}

.arrow-black {
  border-top: 1px solid black;
  border-left: 1px solid black;
  display: block;
  width: 5px;
  height: 5px;
}

.arrow {
  display: block;
  margin: 16px auto;
  width: 12px;
  height: 12px;
  border-top: 1px solid white;
  border-left: 1px solid white;
}

.right {
  transform: rotate(135deg);
}

.left {
  transform: rotate(-45deg);
}

.bottom {
  transform: rotate(225deg);
}

.top {
  transform: rotate(45deg);
}

input {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 9px 15px;
}

.content-padding {
  padding: 40px 110px;
}

@font-face {
  font-family: 'ヒラギノ角ゴ Pro W3';
  src: url('./common/fonts/HiraKakuProN-W3-AlphaNum-03.woff') format('woff'),
    url('./common/fonts/HiraKakuProN-W3-AlphaNum-03.woff2') format('woff2'),
}

@font-face {
  font-family: 'ヒラギノ角ゴ Pro W3';
  font-weight: 600;
  src: url('./common/fonts/HiraKakuStdN-W7-AlphaNum-01.woff') format('woff'),
    url('./common/fonts/HiraKakuStdN-W7-AlphaNum-01.woff2') format('woff2'),
}

@font-face {
  font-family: 'Arial';
  font-weight: normal;
  src: url('./common/fonts/ARIAL.woff') format('woff'),
    url('./common/fonts/ARIAL.woff2') format('woff2'),
}

@font-face {
  font-family: 'Arial';
  font-weight: bold;
  src: url('./common/fonts/ARIALBD.woff') format('woff'),
    url('./common/fonts/ARIALBD.woff2') format('woff2'),
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./common/fonts/NotoSansJP-VariableFont_wght.ttf') format('truetype');
}

@media only screen and (max-width: 576px) {
  .content-padding {
    padding: 40px 20px 40px;
  }
}

@media only screen and (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1220px !important;
  }
}