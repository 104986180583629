.navLink p {
  align-items: center;
  display: flex;
  font-weight: bold;
  flex-direction: row-reverse;
}
.navLink p:hover {
  cursor: pointer;
}

a.navLink {
  color: black;
  text-decoration: none;
}
a.navLink:hover {
  text-decoration: underline;
}
a.navLink:visited, a.navLink:active {
  color: black;
}
