pre {
  word-wrap: break-word;
}

.contactUs h3 {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.contactUs input, .contactUs select {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 15px;
}

.form-content {
  width: 80%;
  margin: auto;
}

.contactUs-doubleInputRow {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
}
.contactUs-doubleInputRow input {
  width: 100%;
}

.contactUs input::placeholder, .contactUs textarea::placeholder { 
  color: #989898;
}
.contactUs input:-ms-input-placeholder, .contactUs textarea:-ms-input-placeholder { 
  color: #989898;
}
.contactUs input::-ms-input-placeholder, .contactUs textarea::-ms-input-placeholder { 
  color: #989898;
}

input::-webkit-inner-spin-button {
  display: none;
}

.contactUs-phoneNumber section {
  display: flex;
  align-items: flex-start;
}
.contactUs-verifyMsg {
  color: #dc3545;
}
#contactUs-areaCode {
  width: 143px;
}

.contactUs-categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  justify-items: center;
  margin: 20px 130px 30px 130px;
}

.contactUs-comment {
  margin-bottom: 80px;
}

.contactUs-textArea {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  height: 200px;
  padding: 9px 15px;
  width: 100%;
}

.contactUs-navigation {
  margin-bottom: 0;
  margin-top: 80px;
  text-align: center;
}

.contactUsDone {
  text-align: center;
}

.contactUsDone-title {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin: 30px 0 14px;
}

.form-zone {
  display: flex;

  .form-title {
    width: 168px;
    font-weight: bold;
  }
}

.contactUs-confirm-content {
  width: 880px;
  margin: auto;
  padding: 40px 0;
}

@media only screen and (max-width: 991px) {
  .contactUs-categories {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 576px) {
  .contactUs-confirm-content {
    width: 100%;
    margin: auto;
    padding: 40px 20px;
  }

  .contactUs-doubleInputRow {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .contactUs-emailInput, .contactUs input {
    width: 100%;
  }

  .contactUs-categories {
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-content {
    width: 100%;
  }
}

.contactUs, .contactUs-content{
  background: #fff;
}