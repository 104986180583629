.contactCategory {
  /* background: linear-gradient(180deg, rgba(34, 101, 202, 0.55) 0%, rgba(33, 81, 152, 0.94) 32.72%, #204377 100%); */
  background: url('../../../images/Contact/contact-bg.png');
  border: 3px solid #9AB4D9;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  color: white;
  text-align: center;
  padding: 17px 20px;
  width: 100%;
  height: 300px;
  position: relative;
}

.contactCategory:hover {
  cursor: pointer;
}

.contactCategory-icon {
  margin-bottom: 12px;
}
.contactCategory img {
  width: 47px;
  height: 47px;
}

.contactCategory-title {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-size: var(--ml-font-size);
  margin-bottom: 0;
  font-weight: 700;
  line-height: 180%;
  transition: all .6s ease-out;
}

.contactCategory-content {
  font-size: 14px;
  margin-top: 10px;
  line-height: 180%;
  letter-spacing: 0.7px;
  transition: all .6s ease-out;
  /* margin-bottom: 0; */
}

.contactCategory-selected {
  background: linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 28.27%, #FFFFFF 100%);
  box-shadow: none;
  border-radius: 8px;
  text-align: center;
}
.contactCategory-selected:hover {
  cursor: default;
}

.contactCategory-selected .contactCategory-title {
  color: var(--primary-color);
}

.contactCategory-selected .contactCategory-content {
  color: black;
}

.contactCategory-selectedMark {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: black;
  height: 20px;
  background: var(--border-color-light);
  border-radius: 10px;
  border: none;
  line-height: 19px;
  width: 80%;

  position: absolute;
  bottom: 10%;
  left: 10%;

  transition: all .6s ease-out;
}
@media only screen and (max-width: 576px) {
  .contactCategory{
    min-height: 202px;
    height: auto;
  }
  .contactCategory-title{
    display: inline-block;
  }
  .contactCategory-content{
    margin-bottom: 0px;
  }
  .contactCategory-selected{
    padding-bottom: 55px;
  }
}