.banner {
  width: 100%;
  height: 580px;
  object-fit: cover;
}

.message-content {
  padding-left: 0;
  padding-right: 0;
  width: 800px !important;
  font-size: 18px;
  padding-bottom: 110px !important;
  .home-navButtonWrapper{
    margin-bottom: 0px;
  }

  .main-title {
    font-size: 42px;
    text-align: center;
    font-weight: 700;
  }

  .sign-zone {
    display: grid;
    grid-template-columns: 50fr 50fr;

    .name {
      font-size: 18px;
      margin-right: 40px;
    }

    .sign-content {
      display: flex;
      align-items: center;

      img {
        width: 200px;
      }
    }
  }
}

.message {
  --about-us-row-title-width: 130px;
  --about-us-title-width: 173px;
  --about-us-subtitle-width: 87px;
  --message-title-width: 173px;
  --message-subtitle-width: 67px;
  --leadership-title-width: 205px;
  --leadership-subtitle-width: 70px;
  --global-office-title-width: 264px;
  --office-title-height: 52px;
}

.message .whiteTitle-subtitle {
  font-family: 'Arial';
  font-size: 20px;
  font-weight: bold;
}

.message h1 {
  margin-top: 10px;
  margin-bottom: 60px;
}

.message video {
  height: var(--page-video-height);
  /* margin-top: 55px; */
  object-fit: cover;
}

.message-aboutUsAndLeadership {
  background-attachment: fixed;
  background-image: url('../../images/company_about_us_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.message-officesHeadline {
  margin-top: 12px;
}



.message-subtitle {
  font-size: var(--ml-font-size);
  line-height: 30px;
  width: var(--message-subtitle-width);
}

.message-aboutUsRow {
  background-color: white;
  border-top: 1px solid var(--border-color);
  display: flex;
}
.message-aboutUsRow:last-of-type {
  border-bottom: 1px solid var(--border-color);
}
.message-aboutUsRow p {
  margin: 0;
}

.message-rowWithLink {
  flex-direction: column;
}
.message-rowWithLink {
  padding: 0 var(--spacing-size-e) var(--spacing-size-e) 0;
}

.message-aboutUsRowContent {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  font-size: var(--ml-font-size);
  letter-spacing: 0.02em;
  line-height: 180%;
  padding: var(--spacing-size-d);
}

.message-aboutUsRowTitle {
  display: flex;
  font-weight: bold;
  padding-right: var(--spacing-size-d);
  min-width: 150px;
}

.message-aboutUsLargeContent {
  padding-top: var(--spacing-size-d);
}

.message-leadership {
  margin-top: var(--about-us-padding-top-down);
}

.message-leadershipCards {
  display: grid;
  gap: var(--spacing-size-d);
  grid-template-columns: repeat(4, 1fr);
}

.message-globalOffices {
  margin-bottom: 100px;
}
.message-globalOffices .message-subtitle {
  width: 53px;
}

.message-globalOfficesTitle {
  display: flex;
  font-size: var(--m-font-size);
  gap: 100px;
}

.message-globalOfficesCards {
  display: flex;
  font-family: var(--secondary-font);
  gap: var(--spacing-5);
  justify-content: space-between;
}

.message-globalOfficesCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
}
.message-globalOfficesCard img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.message-officeCardTitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 150%;
  margin-bottom: 0;
  height: 80px;
  display: flex;
  align-items: center;
}

.message-officeCardContent {
  font-family: 'Arial';
  font-size: 14px;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 0;
  letter-spacing: 0.7px;
}

.message-globalOfficesTitleWrapper {
  display: flex;
  gap: 100px;
  justify-content: space-between;
}

.services-fbLinkArrow {
  margin-left: 20px;
}

@media only screen and (max-width: 960px) {
  .message-leadershipCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .sign-content {
    img {
      width: 176px !important;
    }
  }

  .message-content {
    width: 100% !important;
    padding-bottom: 60px !important;
  }

  .message-aboutUs {
    padding-bottom: 40px !important;
  }

  .message-globalOfficesTitleWrapper {
    flex-direction: column;
    gap: 0;
  }

  .message-globalOfficesTitle, .message-globalOfficesCards {
    flex-direction: column;
  }

  .message-leadershipCards {
    grid-template-columns: repeat(1, 1fr);
  }

  .message-globalOfficesTitle {
    gap: 0;
  }

  .message-globalOfficesCards {
    margin-top: 20px;
  }
  .message-globalOfficesCard img {
    width: 100%;
  }

  .message .whiteTitle-subtitle {
    font-size: 12px;
  }

  .message-aboutUsRowContent {
    grid-template-columns: none;
    text-align: left;
  }

  .message h1 {
    text-align: center;
    font-size: 22px;
  }

  .message .title-wrapper {
    text-align: center;
  }

  .message .title-with-bg-wrapper {
    display: inline-grid;
    text-align: center;
    margin: 0 auto 30px auto;
    padding: 16px;
  }

  .message .title-with-bg {
    padding: 16px 0;
    font-size: 42px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.84px;
  }
  .message-aboutUsRowTitle {
    min-width: none;
  }

  .banner {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .message-content {
    font-size: 16px;
  
    .main-title {
      font-size: 22px;
      text-align: center;
    }
  
    .sign-zone {
      display: grid;
      grid-template-columns: 1fr;

      img {
        width: 172px;
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .message h1 {
    margin-bottom: 40px;
  }

  .message video {
    margin-top: 0;
  }
  
}
