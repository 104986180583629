.careers-step-pc {
  display: grid;
  place-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-radius: 6px;
  height: 80px;
  padding: 8px 16px;
  width: 150px;

  i {
    font-style: normal;
  }
}

.careers-stepWrapper-pc {
  display: flex;
  gap: 36px;
}

.careers-currentStep-pc {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
}

.careers-recruitingSteps-pc {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  justify-content: center;
  margin: 60px 0;

  img {
    width: unset !important;
  }
}

.careers-recruitingStep-pc {
  background: white;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  transition: all .5s ease-out;
}


@media only screen and (max-width: 1024px) {
  .careers-recruitingSteps-pc {
    gap: 20px
  }

  .careers-stepWrapper-pc {
    gap: 20px;
  }

  .careers-step-pc {
    font-size: 12px;
    height: 60px;
    width: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .careers-recruitingSteps-pc {
    gap: 12px
  }

  .careers-stepWrapper-pc {
    gap: 12px;
  }

  .careers-step-pc {
    font-size: 10px;
    height: 56px;
    width: 112px;
  }
}