.leadershipCard {
  --leader-profile-image-height: 115px;
  --profile-text-padding: 28px;

  background-color: white;
  gap: 10px;
  padding-bottom: 20px;
  border-radius: 12px;
}

.leadershipCard-profile {
  align-items: center;
  background-color: var(--primary-color);
  /* display: flex; */
  color: white;
  /* height: 115px; */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.leadershipCard-profile img {
  background-color: white;
  width: 100%;
  height: 315px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.leadershipCard-title {
  font-size: var(--ml-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  @media(min-width: 768px) {
    height: 84px;
  }
}

.leadershipCard-title p {
  margin-bottom: 0;
  line-height: 28px;
}

.leadershipCard-profileText {
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  padding-left: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  text-align: left;
  font-weight: 700;
}

.leadershipCard-profileText p {
  margin-bottom: 0;
}

.leadershipCard-title,
.leadershipCard-content {
  margin-top: 32px;
  padding: 0 40px;
}

.leadershipCard-content {
  text-align: left;
  margin-top: 12px;
}

@media only screen and (max-width: 1200px) {
  .leadershipCard-profile img {
    width: 100%;
  }
}