.newsMenuWrapper {
  position: relative;
}

.newsMenu {
  align-items: center;
  color: white;
  cursor: pointer;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  display: flex;
  gap: 20px;
  position: absolute;
  right: 20px;
  top: -30px;
}

.newsMenu-bars {
  display: inline-block;
}

.newsMenu-bars span:hover {
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 15px;
  height: 1px;
  background-color: white;
  margin: 5px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 6px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -6px) rotate(45deg);
}

.newsMenu-menu {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  gap: 70px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  justify-content: center;
  padding: 4px 0;
  min-height: 10px;
}

.newsMenu-menu span {
  height: 0;
  transition: all .3s ease-out;
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .newsMenu {
    font-size: 12px;
  }

  .newsMenu-menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    padding: 6px 25%;
    text-align: center;
  }

  .newsMenu-menu :first-child {
    grid-column: span 3 / span 3;
  }
}