.newsCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  text-align: center;
  /* padding: 22px; */
}
.newsCard-image {
  transition: transform .7s;
  border: 1px solid var(--border-color-light);
  border-radius: 5px;
  overflow: hidden;
  height: max-content;
  
  img {
    transition: transform .7s;
    height: 126px;
    width: 100%;
    object-fit: cover;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.newsCard-contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.newsCard-headerWrapper {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}
.newsCard-headerWrapper p {
  margin-bottom: 0;
  white-space: nowrap;
}
.newsCard-contentWrapper a {
  text-decoration: none;
}

.newsCard-date {
  font-size: var(--m-font-size);
}

.newsCard-tag {
  background-color: var(--primary-color);
  border-radius: 4px;
  color: white;
  font-size: var(--xs-font-size);
  padding: 2px 10px;
}

.newsCard {
  font-size: var(--sm-font-size);
  text-align: left;
  line-height: 180%;
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
  .newsCard {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .newsCard {
    /* align-items: center; */
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
    min-width: 90%;
    gap: var(--spacing-1);
  }
  .newsCard:last-of-type {
    border-bottom: 1px solid var(--border-color);
  }

  .newsCard-contentWrapper {
    gap: 9px;
    width: 200px;
  }

  .newsCard-headerWrapper {
    flex-direction: row-reverse;
    /* padding: 10px 30px 5px 0; */
  }

  .newsCard img {
    border-radius: var(--spacing-size-b);
    display: flex;
    width: 110px;
    height: 58px;
  }
}
