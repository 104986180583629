.breadcrumb-link a {
  color: #000 !important;
  text-decoration: none;
  &:hover{
    color: #888 !important;
  }
}

.newsdetails-content {
  width: 880px !important;
  margin: auto;
}

.newsdetails-content {
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system, sans-serif;

  p {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 180%;
    margin-bottom: 14px;
  
    img {
      margin: 14px auto;
    }
  }
  h2 {
    margin: 14px 0;
  }

  ul {
    list-style: none;
  }
}

.newsdetails-content h3 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 170%;
}

.newsdetails-contentWrapper img {
  min-width: 250px;
  object-fit: contain;
}

.inspectionWrapper img {
  min-width: auto;
}

.newsdetails-breadcrumb {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  padding: 30px 0px 20px;
}
.newsdetails-breadcrumb .arrow-black {
  display: inline-block;
  margin: 0 7px;
}
.newsdetails-breadcrumb span {
  white-space: nowrap;
}

#newsdetails-breadcrumbNav {
  white-space: pre-wrap;
}

.newsdetails-subtitle {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 200%;
  letter-spacing: 0.02em;
}

.newsdetails-date {
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  text-align: right;
}

.newsdetails-listParagraph {
  margin-bottom: 12px !important;
}

.newsdetails-category {
  display: flex;
  justify-content: flex-end;
}

.newsdetails-contentWrapper {
  align-items: flex-start;
  display: flex;
  gap: 26px;
  padding: 20px 0;
}

#newsdetails-listSquare {
  background: url('../../../images/list_square.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 10px;
  font-weight: 600;
  list-style-type: none;
  margin-top: 12px;
  padding-left: 12px;
  height: 35px;
}

@media only screen and (max-width: 576px) {
  .newsdetails-inspection {
    width: -webkit-fill-available;
  }
  
  .newsdetails-contentWrapper {
    flex-direction: column;
  }

  #newsdetails-listSquare {
    height: 34px;
  }

  .newsdetails-content {
    width: 100% !important;
    p {
      img {
        padding: 0;
      }
    }
  }
}

.headerBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #003391;
}
.footerBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #003391;
}