.header-logo {
  height: 25px;
}

.header-navigation, .header-darkNavigation {
  align-items: center;
  display: flex;
  padding: 26.5px 60px;
}

// .header {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.35);
// }
.header-navigation a {
  color: white;
}

// .header-darkNavigation {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.35);
// }
.header-darkNavigation a {
  color: black;
}

.header-navigation a, .header-darkNavigation a {
  display: inline-block;
  font-size: var(--sm-font-size);
  text-decoration: none;
  margin-right: 50px;
  font-weight: 600;
}
.header-navigation a.header-logoLink, .header-darkNavigation a.header-blackLogoLink, .header-navigation a.header-blackLogoLink {
  margin-right: 60px;
}

.header-navigation a:hover, .header-darkNavigation a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header-navigation a:visited, .header-navigation a:active, .header-navigation a:hover {
  color: white;
}

.header-darkNavigation a:visited, .header-darkNavigation a:active, .header-darkNavigation a:hover {
  color: black;
}

.fakeBustersLink {
  --fake-busters-link-padding-right: 20px;

  margin-top: 10px;
  padding-right: var(--fake-busters-link-padding-right);
  position: fixed;
  right: 0;
  top: 20px;
  z-index: 100; /* 需大於header */
}

.fakeBustersLink:hover {
  cursor: pointer;
}

.nav-eng{
  display: block;
  font-weight: 600;
}

@media only screen and (max-width: 776px) {
  #header {
    display: none;
  }

  .nav-eng{
    display: none;
  }

  .fakeBustersLink {
    top: 70px;
    position: absolute;
    display: none;
  }
}
