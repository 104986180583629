[data-aos="goin"] {
  opacity: 0;
  transform: translateY(100px);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

.culture {
  place-items: center;

  #culturePc {
    place-items: center;
    
    #cultureBgTop {
      width: 100%;
      margin-top: 90px;
    }

    #cultureBgBottom {
      width: 100%;
      transform: rotate(180deg);
    }

    .culture-container {
      width: 100%;
      max-width: 1440px;
      padding: 100px 100px;

      .culture-title {
        display: flex;
        flex-direction: column;
        font-size: 64px;
        font-weight: 700;
        line-height: 56px;
        color: #204377;
        align-items: baseline;
        font-family: 'Noto Sans', sans-serif;

        #subTitle {
          font-size: 36px;
        }
      }

      .culture-content-vertical {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .culture-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 0;

          img:first-child {
            width: 53%;
          }

          img:last-child {
            width: 43%;
          }
        }

        .culture-tags {
          display: flex;
          justify-content: space-between;

          img {
            width: 23%;
          }
        }

        .value-cards {
          display: flex;
          gap: 80px;
          justify-content: center;
          
          .value-card {
            position: relative;
            width: 100%;
            max-width: 468px;
            padding: 20px 28px;

            .card-frame {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: #d9d9d9 2px solid;
              border-radius: 20px;
              box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
              transition: transform 0.5s;

              &:hover {
                transform: scale(1.1);
              }
            }

            img {
              width: 100%;
            }
          }
        }
      }

      .culture-content-horizontal {
        display: flex;
        gap: 30px;
        justify-content: space-between;

        .culture-content {
          display: flex;
          flex-direction: column;
          gap: 60px;

          img {
            width: 100%;
            max-width: 598px;
          }
        }
      }
    }
  }

  #cultureMobile {
    display: none;
  }

  .culture-contactUs {
    display: flex;
    gap: 60px;
    text-align: center;
    justify-content: space-between;

    a {
      width: 100%;
    }
  }
}

@media(max-width: 1280px) {
  .culture {
    .culture-container {
      .culture-title {
        font-size: 56px;

        #subTitle {
          font-size: 24px;
        }
      }

      .culture-content-vertical {
        .value-cards {
          gap: 60px;
        }
      }
    }
  }
}

@media(max-width: 1024px) {
  .culture {
    .culture-container {
      .culture-title {
        font-size: 48px;

        #subTitle {
          font-size: 16px;
        }
      }
    }
  }
}

@media(max-width: 576px) {
  .culture {
    #culturePc {
      display: none;
    }

    #cultureMobile {
      display: inline-block;
      padding-top: 90px;
      width: 100%;

      .culture-container {
        display: grid;
        width: 100%;
        padding: 30px 20px;
  
        .culture-title {
          display: flex;
          flex-direction: column;
          font-size: 44px;
          font-weight: 700;
          line-height: 40px;
          color: #204377;
          align-items: baseline;
          font-family: 'Noto Sans', sans-serif;
          padding-left: 15px;
  
          #subTitle {
            font-size: 24px;
          }
        }

        .culture-content-text1 {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0;
        }

        .culture-content-text2 {
          font-size: 22px;
          margin-bottom: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    .culture-contactUs {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 10px 0 30px 0;
      text-align: center;
      justify-content: center;
    }
  }
}