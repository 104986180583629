.mediakit h2 {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: -0.02em;
  padding: 30px 0px 14px;
}

.mediakit h2:first-of-type {
  padding-top: 0;
}

.mediakit-title {
  position: relative;
}

.mediakit-content-area {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 110px 0 100px 0;
  font-size: 16px;

  .mediakit-content {
    p{
      margin-bottom: 0;
    }
  }

  .mediakit-content-link {
    display: flex;
    gap: 50px;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    img {
      transform: rotate(90deg);
    }

    span {
      display: flex;
      gap: 15px;
      align-items: center;
      color: black;
      text-decoration: none;
    }

    span:hover {
      text-decoration: underline;
    }
  }
}

.mediakit-logos-area {
  display: grid;
  gap: 70px;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  .mediakit-logos-block {
    display: grid;
    gap:60px;
  }

  .mediakit-logos-title {
    display: grid;
    font-size: 46px;
    font-family: var(--font-family);
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.92px;
    position: relative;
    margin-bottom: 10px;
    white-space: nowrap;
    text-align: center;
    gap:25px;
  }

  .dividing-line {
    width: 100%;
    border: 1px solid #989898;
  }

  .title-with-bg-wrapper {
    margin: 0;
  }

  .mediakit-logos-for-web {
    display: grid;
    gap:50px;
    justify-items: center;
    margin-bottom: 60px;

    .logo-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
    }

    .logo-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 290px;
      height: 290px;
      padding: 35px;
      border: 1px solid #bfbfbf;
      border-radius: 20px;
    }

    .logo-image img {
      width: 100%;
      height: 100%;
      transition: transform 0.5s ease-in-out;
    }

    .logo-image:hover img {
      transform: scale(1.2);
    }

    .logo-image.blue {
      background-color: #204377;
    }

    .logo-image.black {
      background-color: #000000;
    }

    .mediakit-logo-download {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      margin-right: 10px;
      margin-bottom: 15px;
  
      a {
        padding: 5px 8px 5px 8px;
        border: 1px solid #bfbfbf;
        font-weight: bold;
        text-decoration: none;
        background-color: white;
        color: black;
        transition: all .3s ease-out;
      }
  
      a:hover {
        background-color: #003f84;
        color: white;
        transition: all .3s ease-out;
      }
    }

    .logo-title {
      display: grid;
      padding-left: 10px;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .logo-info {
      display: grid;
      font-size: 12px;
      padding-left: 10px;
      line-height: 20px;
    }
  }

  .mediakit-logos-for-print {
    display: grid;
    justify-content: center;
    gap: 15px;

    .logo-image {
      display: inline-block;
      width: 100%;
      border: 1px solid #bfbfbf;
    }

    .logo-image.for-print {
      opacity: 1;
      transition: all .2s ease-out;
    }
    
    .logo-image.for-print:hover {
      opacity: 0.7;
    }

    .logo-brand-guideline {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: all .5s ease-in-out;
    }

    .logo-brand-guideline-image {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .logo-brand-guideline-select-block {
      display: flex;
      gap: 8px;
      justify-content: center;

      .logo-brand-guideline-select {
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        background-color: #bfbfbf;
      }

      .logo-brand-guideline-select.selected {
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        background-color: #204377;
      }
    }

    .logo-info-area {
      display: grid;
      gap: 5px;
      justify-items: end;
      padding-right: 10px;

      b {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .mediakit-logo-download {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-bottom: 10px;
    
        a {
          padding: 5px 8px 5px 8px;
          border: 1px solid #bfbfbf;
          font-weight: bold;
          text-decoration: none;
          background-color: white;
          color: black;
          transition: all .2s ease-out;
        }
    
        a:hover {
          background-color: #003f84;
          color: white;
          transition: all .2s ease-out;
        }
      }
  
      .logo-info {
        display: grid;
        justify-items: end;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .mediakit {
    .mediakit-content-area {
      padding: 110px 30px 110px 30px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .mediakit {
    .mediakit-content-area {
      padding: 40px 20px 110px 20px;
    }

    .title-wrapper {
      text-align: center;
    }

    .title-with-bg-wrapper {
      display: inline-grid;
      text-align: center;
      margin: 0 auto 0 auto;
      padding: 16px;
    }

    .title-with-bg {
      padding: 12px 0;
      font-size: 42px;
      font-weight: 600;
      line-height: 42px;
      letter-spacing: -0.84px;
    }
  }
}
